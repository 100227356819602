.error-message {
  color: red !important;
  line-height: 15px !important;
  margin-top: 8px;
  /* word-break: break-word; */
  margin-bottom: 0px;
  font-size: 15px;
}
.sign_up_doffo {
  position: relative;
}
.arabic .Gplus {
  padding: 4px 14px !important;
}
.arabic span.gride_icon {
  margin-left: 6px;
}
.arabic .overlay_top span.heart {
  position: absolute;
  right: auto;
  left: 20px;
}
.arabic .header_search_form .form-select {
  border-left: 1px solid #ccc;
  border-right: none;
  background-position: 7px;
}
.w-100 {
  border-color: red !important;
}
.Toastify__toast--error {
  color: #fff !important;
  background: #e74c3c !important;
}
.fb {
  background: #1976d2;
  color: #fff;
  border-radius: 6px;
  border-color: #1976d2;
  padding: 12px 22px;
  font-size: 15px;
  border: #1976d2;
}
.fb i {
  padding: 5px;
}
.tw button {
  background: #289cef;
  color: #fff;
  border-radius: 6px;
  border-color: #289cef;
  padding: 12px 22px;
  font-size: 15px;
  border: #289cef;
}
.tw button i {
  padding: 5px;
}
.Gplus {
  background: #f7f8fa !important;
  color: #000 !important;
  border-radius: 6px !important;
  border-color: #f7f8fa !important;
  padding: 5px !important;
  font-size: 15px !important;
  border: #f7f8fa !important;
  box-shadow: initial !important;
}
.Gplus div {
  background: #f7f8fa !important;
}
.Gplus i {
  padding: 5px !important;
}
.required-star {
  color: red;
  font-size: 16px;
  margin-left: 2px;
  font-weight: 800;
}

.fixed-header {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background: #fff;
}
.choose-language {
  cursor: pointer;
}

.forgot_modal .modal-body {
  padding: 42px 40px;
}

.form_sub_grp .w-100.form-control {
  border: 1px solid;
}
.login-fields .text_view {
  bottom: auto;
  top: 44px;
}

.location-picker {
  cursor: pointer;
}
.select_market {
  cursor: pointer;
}

.choose-language {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.choose-language img {
  margin-right: 5px;
}

.carousel.carousel-slider {
  height: 400px;
}

.slider-wrapper.axis-horizontal img {
  object-fit: cover;
  height: 400px;
}

.thumbs-wrapper.axis-vertical img {
  height: 60px;
}

.validation-error {
  color: #dc3545!important;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.pac-container {
  z-index: 1200 !important;
}

.common_delete_btn:hover {
  color: #dc3545!important;
}



/* Dummy Purchase CSS */
.light-layout {
  background-color: #f9f9f9;
}



.section-b-space {
  padding-bottom: 70px;
}
/*
.section-t-space, section {
  padding-top: 70px;
} */
.success-text {
  text-align: center;
}
.success-text i {
  font-size: 50px;
  color: #4ead4e;
}
.success-text h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}
.success-text p {
  font-size: 18px;
  text-transform: capitalize;
}

/*.section-b-space {
  padding-bottom: 70px;
}
*/

.product-order h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}
.product-order .total-sec {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 15px 0;
  padding: 15px 0;
}
.product-order .total-sec ul li span {
  float: right;
}
.product-order .final-total h3 {
  display: block;
  margin-bottom: 0;
}


.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize;
}
.order-success-sec .order-detail li {
  display: flex;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 16px;
}
.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize;
}
.order-success-sec .payment-mode p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: #f9f9f9;
}
.delivery-sec h2, .delivery-sec h3 {
  color: #000;
  text-transform: capitalize;
}
.delivery-sec h2, .delivery-sec h3 {
  color: #000;
  text-transform: capitalize;
}
.edit-address{
  background: none !important;
  color: #33a7cc !important;
  border: none !important;
  margin-bottom: -16px;

}
.edit-address svg{
  padding: 4px;
}
.edit-address span{
  font-weight: 500;
}

.coupon-code-text{
  border: none !important;
  border-bottom: 1px solid gainsboro !important;
}
.cancel-button{
  background: #f70000 !important;
  color: #fff !important;
}
.remove-button svg{
  padding: 2px;
  color: #f70000;

  }

.address-list-div{
  margin-bottom: 10px;

    border: 1px solid gainsboro;
}
.address-list-div img {padding: 15px;}
.address-list-div .address-col{
  padding: 17px 5px 5px 4px;
}
.address-list-div .address-col h5{
  font-size: 15px;
}
.address-select-title{
  font-size: 20px !important;
  margin-bottom: 30px !important;
}
.button-ed{
  padding: 30px !important;
}
.address-actions button{
  background: none !important;
  color: #33a7cc !important;
  border: none !important;
  font-weight: bold;
}
.address-actions{
  padding: 25px !important;
}
.address_box .MuiDialog-paperWidthSm{
  width: 600px;
  height:auto;
}
/* Dummy Purchase CSS */

.address_box .MuiDialogContent-root {padding-top: 0;overflow: visible;}

.address_box .MuiDialogActions-root {justify-content:flex-end;padding:15px 24px 20px 24px;}

.address_box .MuiDialogActions-root .theme_btn,.address_box .MuiDialogActions-root .MuiButton-root{border-radius: 5px;width:100px}
.addressmap h3 {font-size: 16px;font-weight: 500;}
.addressmap h3 {font-size: 16px;font-weight: 500;}

.address_box .MuiDialogTitle-root {background: #33a7cc;color: #fff;}
.css-1pahdxg-control {border-color: #33a7cc!important; box-shadow: none!important; }
.cat-search{    margin-top: 7px;}
.subscription-mod a{
  width: 100%;
}
.remaining-boost li {
  background: none !important;
  border: 2px solid #a6a6bb !important;
  padding: 12px !important;
  color: #000 !important;
}
.title-r-boost{
  margin-left:12px !important;
}
.description-r-boost{
  float:right !important;
}
.radio-remaining{
  height: 18px !important;
  width: 23px !important;
  position: relative !important;
  top: 4px !important;
}
.remaining-boost .active{
  border: 2px solid #111113 !important;
  background-color: #33a7cc !important;
  color: #fff !important;
}
.boost-description-cs{
  display: flex !important;
}
.boost-description-cs p{
  margin-top: 0px;
  margin-bottom: 0px;
}
.boost-h3s{
  font-size: 15px !important;
}
.card-add-cs{
  width: 100% !important;
    background: #33a7cc !important;
    border-color: #33a7cc !important;
    color: #fff !important;
}
.rating-submit{
  border-color: #33a7cc !important;
}
.selected-feedback{
  background: #33a7cc !important;
    color: #fff !important;
}
.rating-btn{
  width: 30% !important;

}
.phone-input-cs .flag-dropdown {
  display:none;
}
.phone-input-cs .form-control{
  padding-left: 8px !important;
}
.chat-box-left-cs {
  height: calc(800px - 84px);
  overflow: auto !important;
}
.chat-box-right-cs{
  height: 800px;
  /*overflow: auto !important;*/
}
.chat-all-cs{
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.default-chat-cs{
  height:100%;
  width:100%;
}
.panel_bottom .type-tool {
    position: relative !important;
    top: 12px !important;
}
.search-box .form_field{
  width:100% !important;
}
.search-box-input-cs{
  border-radius: 50px !important;
  border: 1px solid #33a7cc !important;
}
.search-chat-crose-icon {
  position: absolute;
  right: 42px;
  background: #fff;
}
.accepted-chat-cs{
  display: block !important;
}
.offer-accepted-cs{
  font-weight: 700 !important;
    color: #000 !important;
    padding: 0px 0px !important;
}
.accepted-time-cs{
  font-size: 12px !important;
}

.accepted-time-cs svg{
  font-size: 14px !important;
}
.paymethod{
  color: #000 !important;
  font-weight: 500 !important;
}
.accepted-offer-main-div-cs{
  padding: 10px;
  line-height: 22px;
}
.moreinfo-accordian{
  margin-top:-30px !important;
}

.chating_list li.sender-msg:last-child {
  margin-bottom: 35px;
}
.reschedule-button-cs{
  width: 100% !important;

}
.accept-button-cs{
  width: 100% !important;
  background: green !important;
  border: green !important;
}
.button-cs-row{
  margin-bottom: 10px !important;
}
.same_input .MuiOutlinedInput-input {
  padding: 0px 10px!important;
}
.same-input .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color: #e9e9e9;
}
.get-direction-cs{
    text-decoration: none;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 18px;
    background: green;
    font-weight: 500;
}
.pay-here-cs{
  margin-top: 30px;
}
.pay-here-cs .green-btn{
  width:100% !important;
}
.pay-here-thumb-outer-cs{
  max-width: 100% !important;
  flex: auto !important;
}

.pay-here-cs .green-btn{
  background: #33a7cc !important;
    border: 1px solid #33a7cc !important;
}
.pay-here-card-img img{
  width: 35px !important;
}

.shipping-label-cs{
  text-align: center;
  width:100% !important;
}
.shipping-label-cs{
  margin-top: 0px !important;
}
.report-problem{
  font-size: 15px !important;
}
.review-offer-accordion{
  margin-left: -26px;
}
.review-offer-accordion .accordion-button.collapsed:after,.review-offer-accordion .accordion-button:after{
  top :-1 !important;
}
.review-offer-accordion .accordion-body{
  padding: 13px 0 0 30px !important;
}
.review-offer-accordion .buyer_detail_contant ul li span{
  width: 150px !important;
}
.review-offer-address .order_detail_box{
  margin-bottom: 0px !important;
}
.review-offer-address .order_detail_box {

  padding: 0px 10px !important;

}

.review-offer-address .order_detail_head {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}
.address-review .address-list-div .address-col {
  padding: 5px 7px 5px 30px !important;
}
.close_btn{
  background: #33a7cc !important;
  border-color: #33a7cc !important;
  padding: 7px 15px !important;
  height: 40px !important;
  color: #fff !important;
  border-radius: 50px !important;
}
.coupon-code-button button{
  width: 100% !important;
}
.return-btn-cs .view-cs-btn{
  background: none !important;
  color: #33a7cc !important;
  font-weight: 500 !important;
  border: 1px solid #33a7cc !important;
  width: 50% !important;
  margin-bottom: 10px !important;
}
.return-btn-cs .approve-btn-cs{
  border: 1px solid #22b81d !important;
  margin-bottom: 10px !important;
  font-weight: 500 !important;
  width: 50% !important;
}
.return-btn-cs .reject-btn-cs{
  background: red !important;
    border: 1px solid red !important;
    font-weight: 500 !important;
    width: 50% !important;
}
.counter-card-model li{
  max-width:100% !important;
}
.counter-card-model li img{
  width:50px;
}
.offer-list-cs .offer_page_wrapper{
  padding: 20px 5px;
}
.offer-list-cs .outline_wrapper{
  margin-bottom: 8px !important;
}
.offer-list-service-cs button{
  margin-right: 5px;
}
.check-svg-cs svg {

  padding: 3px;
  margin-top: -1px;
  color: #33a7cc;

}
.MuiAlert-filledError{
  background: #e74c3c !important;
  color: #fff !important;
}
.text-color-map-cs{
  color:#000 !important;
}
.map-search i{
  position: relative !important;
  top: 43px !important;
  left: 18px !important;
}
.map-overlay{
  margin-bottom: 5px !important;
}




.login_with .Gplus,.login_with > span {flex: 0 0 50%;max-width: calc(50% - 20px);margin: 0 10px;justify-content: center;}

.login_with > span .fb.metro {width: 100%;position: relative;overflow: hidden;}

.login_with .Gplus {background: #df4a32!important;
  position: relative;margin-right:8px;
  overflow: hidden;
  padding: 12px!important;}
  .login_with .Gplus span {padding: 0!important;}
.login_with .Gplus * {color: #fff;}

.login_with .Gplus > div {position: absolute;left: 0;top: 0;bottom: 0;padding: 12px 10px!important;background: #9c3322!important;width: 40px;}

.login_with > span .fb.metro i {margin-right:8px;position: absolute;left: 0;padding: 12px;background: #0360bd;top: 0;bottom: 0;width: 40px;}
.border-gray-100 {border-color: #f1f1f1!important;}
.mt-64{margin-top:64px!important;}
.smalll-imgage {height: 52px;width: 52px;overflow: hidden;border-radius: 5px;border: 1px solid #ddd;}
.smalll-imgage img {width: 100%;height: 100%;object-fit: cover;}
.loader_image > div {z-index: 9999;}

.offer-select-address  li {flex: 0 0 100%;max-width: 100%!important;margin: 10px 0!important;border-radius: 6px; cursor: pointer;}
.select-address-btn-spacing {margin: 0 0 15px 0px!important;}
.settings-tray .user_rating small {vertical-align: top;font-size: 14px; padding-top:5px;}
.chat-image  img {width: 150px;height: auto;}
.chat-image {display: flex;align-items: center;width: 100%;justify-content: center;height: 100%;}
.chat-outerbx .buyer-cardinfo.buyer-detail-card {margin-bottom: 0;}
.ChatContent>button { background: #31abd3 !important; }
.location_modal.schedule-meetup-modal .form-control { height: 50px!important;border: 1px solid rgba(0,0,0,.2); background-color:#fff;}
.location_modal.schedule-meetup-modal .form-control:hover {border-color: rgba(0, 0, 0, 0.87);}
.location_modal.schedule-meetup-modal .map-search i {
  position: relative !important;
  top: 37px !important;
  left: 24px !important;
}
.point-list {list-style: none;margin: 0;padding: 0;margin: 0 -15px;}

.point-list li {padding: 5px 16px;font-weight: 500;border-right: 1px solid #ddd;line-height: 1;}

.point-list li:last-child {border: none;}
.final_price .final_costing_head {justify-content: space-between;flex-direction: row; align-items:center;}
.ChatContent>button {
  position: absolute;
  right: 0px;
  background: #0dcaf0 !important;
  padding: 20px;
}